import React from 'react'
import Layout from "../components/layout"
import scandal from '../images/best-party-2.png'
import SEO from '../components/seo'

export default function Article() {
    return (
        <Layout>
            <SEO title={`The Best Dirt from “The Best Party of the Year”`} image={scandal} />
            <h1>
            The Best Dirt from “The Best Party of the Year”
            </h1>
            <img src={scandal} />
            <p>In case you weren’t at the Mayor’s party for the troops last night: 1) What is wrong with you? 2) You missed one for the books! 
            Dancing, nice weather, good music, great food… But I know that’s not why you come to this source. You want all the dirt. So here we go…</p> 

            <p>Everyone’s favorite bickering couple, Beatrice and Benedick, had only one small quarrel, much to the disappointment of partygoers eager to see them reunited after a year separated by war. Perhaps there may have been a larger battle of wits if Beatrice hadn’t spent much of the night hiding from Benedick.</p> 

            <p>“The lady doth protest too much, methinks.”</p>

            <p>Looks like yet another suitor has fallen in love with our sweet Hero. Claudio, the decorated and oh-so-handsome young soldier from Florence, held Hero in his arms through every dance of the night. (I wish a man would gaze into my eyes like that!)</p>

            <p>One partygoer who was looking less than thrilled? Don John. The General’s stepbrother was looking a bit “overserved” as he glared in the direction of the new couple all night. His face may have been red with anger, but we all know he was actually green with envy. (Faithful readers will recall my theories on Don John’s long and unrequited love of Hero.)</p>

            <p>Claudio gets to be the war hero and win the girl?!</p>
        </Layout>
    )
}
